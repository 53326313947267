import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Seo from "/src/components/Seo"
import Layout from "/src/components/Layout"

const IndexPage = ({ data }) => {
  const title = data?.allFile?.nodes[0]?.childMdx?.frontmatter?.title
  const body = data?.allFile?.nodes[0]?.childMdx?.body

  return (
    <>
      <Seo title={title} />
      <Layout>
        {body ? (
          <MDXRenderer>{body}</MDXRenderer>
        ) : (
          "This page has not been translated yet"
        )}
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($locale: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "index-page" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
          }
          body
        }
      }
    }
  }
`
